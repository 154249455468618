/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@mixin heading($line_height:$line_height_base, $line_height_m:$line_height_small, $font_size:$font_size_base, $font_size_m:$font_size_small) {
	display:block;
	line-height:$line_height;
	font-size:$font_size;

	@media screen and (max-width:768px)
	{
		line-height:$line_height_m;
		font-size:$font_size_m;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin:0;
	font-weight:700;
}
h1, .h1 {
	@include heading(1.2, 1.4, 64px, 32px);
}
h2, .h2 {
	@include heading(1.3, 1.5, 48px, 24px);
}
h3, .h3 {
	@include heading(1.4, 1.5, 32px, 24px);
}
h4, .h4 {
	@include heading(1.5, $line_height_base, 24px, $font_size_base);
}
h5, .h5 {
	@include heading;
}
h6, .h6 {
	@include heading($line_height_small, $line_height_small, $font_size_small, $font_size_small);
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
	height:0;
	overflow:visible;
	box-sizing:content-box;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
	color:map-get($colors, _01-01);
	text-decoration:none;
	text-decoration-skip:objects;
	background-color:transparent;

	&:active,
	&:hover {
		outline-width:0;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
	font-weight:inherit;
	font-weight:bold;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
	font-style:italic;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
	color:#333;
	background:none;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
	font-size:$font_size_small;
}

sub,
sup {
	position:relative;
	line-height:0;
	font-size:75%;
	vertical-align:baseline;
}
sub {
	bottom:-0.25em;
}
sup {
	top:-0.5em;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
	display:block;
	margin:0;

	figcaption {
		display:block;
	}
}

img {
	width:auto\9; height:auto;
	vertical-align:middle;
	border:0;
	-ms-interpolation-mode:bicubic;

	&.align_left {
		float:left;
	}
	&.align_right {
		float:right;
	}
}

svg:not(:root) {
	overflow:hidden;
}
