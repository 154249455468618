/* ------------------------------------------------------------------------------------------------------------------------ */
/* OFFERS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flash-messages_offer {
	left: 6px;
	right: 6px;
	bottom: 58px;
	z-index: 99998;
	font-size: $font_size_base;

	@media screen and (min-width: 769px) {
		bottom: 6px;
	}

	.flash-message {
		@include relative;
		max-width:none;

		& > .box {
			@include flex-row;
			justify-content:center;
			width:100%;

			@media screen and (max-width:768px)
			{
				flex-direction:column;
				align-items:flex-start;
				line-height:$line_height_small;
				font-size:$font_size_small;
				text-align:left;
			}

			& > .btn {
				text-decoration:underline;

				@media screen and (min-width:769px)
				{
					margin-left:24px;

					&:hover,
					&:focus {
						text-decoration:none;
					}
				}
				@media screen and (max-width:768px)
				{
					margin-top:12px;
				}
			}
		}

		&.is-dismissed {
			opacity:0;
			transform:translateY(100%);
			visibility:hidden;
		}
	}
}
