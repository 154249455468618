/* --------------------------------------------------------------------------------------------------- */
/* PANEL --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.panel {
	@include animate;
	position:fixed;
	top:0; left:0; right:0; bottom:0;
	z-index:1001;
	background:rgba(map-get($colors, _01-01), .88);
	opacity:0;
	visibility:hidden;

	&.is-active {
		opacity:1;
		visibility:visible;

		.panel-container {
			transform:translateX(0);
		}
	}
}

	.panel-close {
		@include flex-column;
		justify-content:center;
		align-items:center;
		position:absolute;
		top:0; left:0;
		z-index:1;
		width:56px; height:56px;
		background:#FFF !important;
		border:none;

		@media screen and (max-width:768px)
		{
    		width:$header_height_01-03; height:$header_height_01-03;
            background:none !important;
        }

		svg {
			@include animate;
			display:block;
			width:18px; height:18px;
			fill:map-get($colors, _01-01);

			@media screen and (max-width:768px)
    		{
    			fill:#FFF;
            }
		}
	}

	.panel-container {
		@include animate;
		position:absolute;
		top:0; right:0; bottom:0;
		z-index:1;
		height:100%;
		background:#FFF;
		box-sizing:border-box;
		overflow-y:auto;
		transform:translateX(12px);

		@media screen and (min-width:769px)
		{
			padding:48px;
			width:100%; max-width:$site_width_xsmall;
		}
		@media screen and (max-width:768px)
		{
			padding:36px 48px;
			width:calc(100% - #{$header_height_01-03});
		}
	}

	.panel-trigger {

		@media screen and (max-width:768px)
		{
			@include flex-row;
			justify-content:space-between;
			align-items:center;
			position:fixed;
			left:0; right:0; bottom:0;
			z-index:99;
			padding:13px 24px;
			width:100%;
			font-weight:500;
		}

		svg {

			@media screen and (max-width:768px)
			{
				display:block;
				width:18px; height:18px;
			}
		}
	}
