/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.search-wrapper {

    @media screen and (min-width:769px)
    {
        margin-top:-48px;
    }
}
    .search-item {
        padding-top:24px;
        border-top:1px solid map-get($colors, _01-03);

        &:not(:first-child) {
            margin-top:24px;
        }
        &:last-child {
            padding-bottom:24px;
            border-bottom:1px solid map-get($colors, _01-03);
        }
    }
        .search-item_heading {
            @include flex-row;
            justify-content:space-between;
            align-items:center;

            &:not(:only-child) {
                padding-top:6px;
            }

            h3 {
                padding-right:24px;
            }
        }
            a.search-item_link {
                @include animate;
                color:map-get($colors, _02-01);

                svg {
                    @include animate;
                    width:18px !important; height:18px !important;
                    fill:map-get($colors, _02-01);
                }

                @media screen and (min-width:769px)
        		{
        			&:hover,
        			&:focus {
        				color:map-get($colors, _02-02);

                        svg {
                            fill:map-get($colors, _02-02);
                        }
        			}
        		}
            }
