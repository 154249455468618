/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.wrapper_video {
	@include relative;
	@include figure_aspect-ratio('9 / 16');

	video,
	iframe {
		position:absolute;
		top:0; left:0;
		z-index:1;
		width:100%; height:100%;
	}
}

/* --------------------------------------------------------------------------------------------------- */
/* VIDEO (MODAL) ------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.modal-video_wrapper {

	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:column;
		justify-content:center;
		padding:24px;
		background:rgba(#000, .88) !important;
	}
}
