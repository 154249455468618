/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE CATEGORIES ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.archive-categories {
	@include relative;
	// margin-left:auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE TABS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.archive-tabs {
	padding-top:12px;

	@media screen and (max-width:768px)
	{
		padding-top:6px;
	}
}
	.archive-tabs_list {
		@include flex-row;
		list-style:none;
	}
		.archive-tabs_item {

			&:not(:last-child) {
				padding-right:32px;

				@media screen and (max-width:768px)
				{
					padding-right:24px;
				}
			}
		}
			.archive-tabs_link {
				@include animate;

				@media screen and (min-width:769px)
				{
					&:not(.is-current):hover,
            		&:not(.is-current):focus {
						color:map-get($colors, _02-02);
					}
				}

				&.is-current {
                    cursor:default;
                    color:map-get($colors, _02-02);
                }
			}
