/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (PRIMARY) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-primary {
    @include flex-column;
    width:100%;
}
    .nav-primary_list {
        list-style:none;

        @media screen and (min-width:769px)
        {
            @include flex-row;
            flex:1 1 auto;
        }
    }
        .nav-primary_list-item {
            @include flex-row;
            @include relative;
			align-items:center;
            flex-shrink:0;

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {

                    .nav-primary_submenu {
                        opacity:1;
                        visibility:visible;
                        transform:translateY(0);
                    }
                }
            }
            @media screen and (max-width:768px)
            {
                flex-wrap:wrap;

                &:not(:first-child) {
                    padding-top:12px;
                }
            }

			&:not(:last-child) {

                @media screen and (min-width:769px)
                {
                    padding-right:32px;
                }
			}

            &.has-submenu:after {

                @media screen and (min-width:769px)
                {
                    @include absolute;
                    content:"";
                    left:0; right:0; bottom:-24px;
                    height:24px;
                }
            }

			&:not(.has-submenu):after,
            .nav-primary_list-item_link-chevron:after {
				content:"";
				display:block;
				width:8px; height:8px;
				background:map-get($colors, _02-01);
				border-radius:100%;
			}

            &.nav-primary_list-item_highlight {

                .nav-primary_list-item_link {
                    color:map-get($colors, _02-02);
                }
                &:not(.has-submenu):after {
                    background:map-get($colors, _02-02);
                }
            }
        }

            .nav-primary_list-item_link {
                @include animate;
                @include relative(2);
                display:block;
				padding-right:9px;
                font-weight:700;

                @media screen and (min-width:769px)
            	{
                    line-height:1;

                    &:not(.is-current):hover,
            		&:not(.is-current):focus {
            			color:map-get($colors, _02-01);
            		}
                }
                @media screen and (max-width:768px)
                {
                    font-size:24px;
                }

                &.is-current {
                    cursor:default;
                    color:map-get($colors, _02-01);
                }
            }

            .nav-primary_list-item_link-chevron {
                @include relative;

                &:before {
                    @include animate;
                    @include absolute(1);
                    content:"";
                    top:50%; left:50%;
                    width:32px; height:32px;
                    background:rgba(map-get($colors, _02-01), .12);
                    border-radius:100%;
                    transform:translate(-50%, -50%);
                    opacity:0;
                }
                &:after {
                    @include relative(2);
                }

                @media screen and (min-width:769px)
            	{
                    &:hover:before,
            		&:focus:before {
            			opacity:1;
            		}
                }

                svg {
                    @include absolute;
                    display:block;
                    left:50%; bottom:-19px;
                    margin-left:-5px;
                    width:10px; height:10px;
                    fill:rgba(map-get($colors, _01-01), .48);
                    transform:rotate(90deg);

                    @media screen and (max-width:768px)
                    {
                        display:none;
                    }
                }
            }

.nav-primary_submenu {
	list-style:none;
    transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, transform 300ms ease-in-out;

    @media screen and (min-width:769px)
	{
		@include absolute(2);
		top:38px;
        padding:20px 24px;
		min-width:240px; max-width:300px; max-height:335px;
		background:rgba(map-get($colors, _01-03), .88);
		opacity:0;
		visibility:hidden;
        overflow:auto;
        transform:translateY(6px);
		transition-duration:300ms;
		transition-timing-function:ease-in-out;

        &.open {
            opacity:1;
            visibility:visible;
            transform:translateY(0);
        }

        &:not(.pos-right) {
            left:auto;
    		margin-left:-24px;
        }
        &.pos-right {
            right:0;
    		margin-right:-24px;
        }
	}
    @media screen and (max-width:768px)
    {
        padding-top:6px;
        width:100%;
    }
}
    .nav-primary_submenu-item {

        &:not(:first-child) {
            padding-top:6px;
        }
    }
        .nav-primary_submenu-item_link {
            @include animate;

            @media screen and (min-width:769px)
            {
                &:not(.is-current):hover,
                &:not(.is-current):focus {
                    color:map-get($colors, _02-01);
                }
            }
            @media screen and (max-width:768px)
            {
                color:rgba(map-get($colors, _01-01), .64);
            }

            &.is-current {
                cursor:default;
                color:map-get($colors, _02-01);
            }
        }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SIDE) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-side {

    @media screen and (min-width:769px)
    {
        margin-left:24px;
    }
}
    .nav-side_list {
        list-style:none;
    }
        .nav-side_item {

            @media screen and (min-width:769px)
            {
                @include flex-row;
                align-items:center;
                flex-shrink:0;
            }

            &:not(:first-child) {
                padding-top:12px;
            }

            &:before {

                @media screen and (min-width:769px)
                {
                    content:"";
                    display:block;
                    margin-top:2px;
                    width:8px; height:8px;
                    background:map-get($colors, _02-01);
                    border-radius:100%;
                }
            }
        }
            .nav-side_link {

                @media screen and (min-width:769px)
                {
                    @include animate;
                    display:block;
                    padding-left:9px;
                    line-height:1;

                    &:not(.is-current):hover,
                    &:not(.is-current):focus {
                        color:map-get($colors, _02-01);
                    }
                }
                @media screen and (max-width:768px)
                {
                    font-size:24px;
                }

                &.is-current {
                    cursor:default;
                    color:map-get($colors, _02-01);
                }
            }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (FOOTER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-footer {
}
    .nav-footer_list {
        padding-top:24px;
        list-style:none;

        @media screen and (max-width:768px)
        {
            padding-top:9px;
        }
    }
        .nav-footer_list-item {
            @include flex-column;
            align-items:flex-start;
        }
            .nav-footer_list-item_link {
                @include animate;
                line-height:1.8;
                font-size:$font_size_small;

                @media screen and (max-width:768px)
            	{
                    line-height:1.4;
                    font-size:14px;
                }
                @media screen and (min-width:769px)
                {
                    &:hover,
                    &:focus {
                        color:map-get($colors, _02-01);
                    }
                }
            }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (SOCIAL) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-social {
}
    .nav-social_list {
        @include flex-row;
        padding-top:12px;
        list-style:none;
    }
        .nav-social_list-item {

            &:not(:last-child) {
                padding-right:18px;
            }
        }
            .nav-social_list-item_link {

                svg {
                    @include animate;
                    display:block;
                    width:18px; height:18px;
                    fill:map-get($colors, _01-01);
                }

                @media screen and (min-width:769px)
                {
                    &:hover svg,
                    &:focus svg {
                        fill:map-get($colors, _02-02);
                    }
                }
            }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION (CATEGORIES) ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.nav-categories {
    display:inline;
    list-style:none;
}
    .nav-categories_item {
        display:inline;

        &:not(:last-child)::after {
            content: ", ";
        }
    }
        .nav-categories_link {
            @include animate;
            color:map-get($colors, _02-01);
            text-decoration:underline;

            @media screen and (min-width:769px)
            {
                &:hover,
                &:focus {
                    color:map-get($colors, _02-01);
                    text-decoration:none;
                }
            }
        }
