/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
	@include animate;
	@include flex-column;
	flex-shrink:0;
	padding-top:$header_height_01-01;
	min-height:calc(100vh - #{$header_height_01-01});

	@media screen and (max-width:768px)
	{
		padding-top:$header_height_01-03;
		min-height:calc(100vh - #{$header_height_01-03});
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-container {
	@include flex-column;
	flex:1 1 auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IS STICKY HEADER ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.is-sticky-header {

	.wrapper {

		@media screen and (min-width:769px)
		{
			padding-top:$header_height_01-02;
			min-height:calc(100vh - #{$header_height_01-02});
		}
	}
}

.fb-pixel-noscript {
	display:none;
	width:1px; height:1px;
}
