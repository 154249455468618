/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.articles-wrapper {
	margin-top:-48px;

	@media screen and (max-width:768px)
	{
		margin-top:-24px;
	}
}

	.articles-heading {
		@include flex-row;
		align-items:flex-end;

		span {
			margin-left:2px;
			line-height:1;

			@media screen and (min-width:769px)
			{
				margin-bottom:-6px;
			}
		}
	}

.article-grid_slide {
	@include flex-column;
	height:auto;

	.event-item {
		padding-top:0;
	}
}
	.article-grid_item {
		@include flex-column;
		flex:1 1 auto;
	}

.articles-heading {
	padding-bottom:48px;

	@media screen and (max-width:768px)
	{
		padding-bottom:24px;
	}
}

	.article-item > .column:not(:first-child) {

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}

		.article-thumb {
			@include relative;
			overflow:hidden;

			@media screen and (min-width:769px)
			{
				&:hover .article-thumb_image-wrapper,
				&:focus .article-thumb_image-wrapper {
					transform:scale(1.05);
				}
			}
		}
			.article-thumb_link {
				@include full-size(2);

			}
				.article-thumb_image-wrapper {
					@include animate;
				}

		.article-meta,
		.article-single_meta {
			@include flex-row;
		}
		.article-single_meta {
			padding-top:48px;

			@media screen and (max-width:768px)
	        {
				padding-top:12px;
			}
		}
			.article-categories,
			.article-date {
				line-height:$line_height_small;
				color:map-get($colors, _02-01);
				font-size:$font_size_small;
			}
			.article-date {
				padding-right:4px;
			}

		.article-heading {
			padding-top:24px;
			color:map-get($colors, _01-01);

			@media screen and (max-width:768px)
			{
				padding-top:9px;
			}
		}

		.article-excerpt {
			padding-top:12px;
		}

		.article-cta_wrapper {
			@include flex-column;
			align-items:flex-start;
			padding-top:48px;

			@media screen and (max-width:768px)
			{
				padding-top:18px;
			}

			.btn {

				&:after {
					background:map-get($colors, _02-01);
				}

				@media screen and (min-width:769px)
				{
					&:not(:disabled):hover,
					&:not(:disabled):focus {
						color:map-get($colors, _02-01) !important;
					}
				}
			}
		}

		.article-content {

			@media screen and (min-width:769px)
			{
				@include flex-column;
				flex:1 1 auto;
				padding:24px 24px 0 0;
			}
			@media screen and (max-width:768px)
			{
				padding-top:24px;
			}

			.article-heading {

				@media screen and (min-width:769px)
				{
					padding-top:12px;
				}
			}

			.article-cta_wrapper {

				@media screen and (min-width:769px)
				{
					margin-top:auto; padding-top:24px;
				}
			}
		}

.articles-prev,
.articles-grid_prev,
.articles-next,
.articles-grid_next {

	@media screen and (max-width:768px)
	{
		top:0;
		height:auto;
		transform:none;

		svg {
			@include absolute;
		}
	}
}
.articles-prev,
.articles-grid_prev {

	@media screen and (max-width:768px)
	{
		&:before {
			top:50%;
			transform:translate(-50%, -50%);
		}

		svg {
			margin-left:-4px;
		}
	}
}
.articles-next,
.articles-grid_next {

	@media screen and (max-width:768px)
	{
		&:before {
			top:50%;
			transform:translateY(-50%);
		}

		svg {
			margin-right:0;
			transform:translateX(4px);
		}
	}
}
