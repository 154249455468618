/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
	position:fixed;
	top:0; left:0; right:0;
	z-index:99;
	padding:0 96px;
	background:#FFF;
	border-bottom:1px solid map-get($colors, _01-03);

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:0 48px;
	}
	@media screen and (max-width:768px)
	{
		padding:0 12px;
	}
}
	.header-content {
		@include flex-row;
		@include relative;
		padding:32px 0;

		@media screen and (max-width:768px)
		{
			justify-content:space-between;
			align-items:center;
			padding:12px 0;
		}
	}
		.header-lhc {

			@media screen and (min-width:769px)
			{
				padding-right:64px;
			}
		}
		.header-rhc {
			@include flex-column;
			justify-content:space-between;
			flex:1 1 auto;
		}
			.header-rhc_top {
				@include flex-row;
				justify-content:flex-end;
				font-size:$font_size_small;
			}

	.header-link {
		@include animate;
		color:map-get($colors, _01-01);

		@media screen and (max-width:768px)
		{
			@include flex-column;
			justify-content:center;
			align-items:center;
			width:48px; height:48px;
			background:map-get($colors, _01-03) !important;
			border-radius:100%;
			overflow:hidden;
		}

		&:not(:last-child) {
			margin-right:32px;

			@media screen and (max-width:768px)
			{
				margin-right:6px;
			}
		}

		.btn-icon {

			@media screen and (max-width:768px)
			{
				padding-right:0 !important;
			}
		}

		svg {
			@include animate;
			fill:map-get($colors, _01-01);

			@media screen and (max-width:768px)
			{
				width:18px !important; height:18px !important;
			}
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				color:map-get($colors, _02-01) !important;

				svg {
					fill:map-get($colors, _02-01) !important;
				}
			}
		}
	}

	.header-cta {
		@include animate;
		@include flex-column;
		@include absolute(2);
		justify-content:center;
		align-items:center;
		right:0; bottom:-64px;
		width:128px; height:128px;
		font-weight:700;
		border-radius:100%;
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IS STICKY HEADER ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.is-sticky-header {

	.header-cta {
		bottom:-48px;
		width:96px; height:96px;
	}
}
