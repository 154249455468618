/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
	margin:0; padding:0;
	font-size:100%;
	vertical-align:baseline;
}

html {
	text-size-adjust:100%;
	-webkit-font-smoothing:antialiased;
	background-color:#FFF;
}

body {
	margin:0; padding:0;
	line-height:$line_height_base;
	color:map-get($colors, _01-01);
	font-family:$font_family_01;
	font-size:$font_size_base;
	font-weight:500;
	overflow-x:auto; overflow-y:scroll;

	@media screen and (max-width:768px)
	{
		line-height:$line_height_small;
		font-size:$font_size_small;
	}
}

.plyr__controls {
	display: none !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display:block;
}

audio, canvas, video {
	display:inline-block;
	*display:inline;
	*zoom:1;
}
audio:not([controls]) { display:none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
	color:#FFF;
	background-color:map-get($colors, _01-01);
}
::-moz-selection {
	color:#FFF;
	background-color:map-get($colors, _01-01);
}




