/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_wrapper {
	transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out;
	position:fixed;
	top:0px; left:0px; right:0px; bottom:0px;
	z-index:99999;
	background:rgba(map-get($colors, _01-01), .88);
	opacity:0;
	visibility:hidden;

	@media screen and (max-width:768px)
	{
		background:#FFF;
		overflow:auto;
	}

	.js-modal_content {

		@media screen and (min-width:769px)
		{
			position:absolute;
			top:50%; left:50%;
			z-index:2;
			width:calc(100% - 96px);
			transform:translate(-50%, -50%);

			&:before,
			&:after {
				content:"";
				display:block;
				width:100%; height:48px;
			}
		}
	}

	.js-modal_trigger {
		display:inline-block;
	}

	&.fixedHeight {
		overflow:auto;

		.js-modal_content {
			@include relative;
			top:auto; left:auto;
			margin:0 auto;
			transform:none;

			@media screen and (max-width:768px)
			{
				margin:0;
			}
		}
	}

	&.open {
		opacity:1;
		visibility:visible;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_trigger_no-js {
	display:none !important;
	opacity:0 !important;
	visibility:hidden !important;
}
.no-js {

	.js-modal_wrapper {
		z-index:1;

		&:target {
			z-index:99999;
			opacity:1;
			visibility:visible;
		}
	}

	.js-modal_trigger_no-js {
		display:block !important;
		opacity:1 !important;
		visibility:visible !important;

		& + a {
			display:none !important;
			opacity:0 !important;
			visibility:hidden !important;
		}
	}
}
