/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero {
  @include relative;
  margin-bottom: -1px;
  color: map-get($colors, _02-01);
  background: #fff;
  overflow: hidden;
}
.hero-content {
  @include flex-row;
  background: #fff;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.hero-lhc,
.hero-rhc {
  @include flex-column;
}
.hero-lhc {
  justify-content: center;
  flex-shrink: 0;
  padding: 48px 96px;
  box-sizing: border-box;

  @media screen and (max-width: 1220px) and (min-width: 769px) {
    padding: 48px;
  }
  @media screen and (min-width: 769px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    order: 2;
    padding: 24px;
  }
}
.hero-rhc {
  @media screen and (min-width: 769px) {
    width: 75%;
  }
}

.hero-heading {
  padding-top: 24px;
  color: map-get($colors, _01-01);

  @media screen and (max-width: 768px) {
    padding-top: 9px;
  }
}

.hero-price {
  padding-top: 48px;

  @media screen and (max-width: 768px) {
    padding-top: 18px;
  }
}

.hero-actions {
  @include flex-row;
  padding-top: 24px;

  @media screen and (max-width: 768px) {
    padding-top: 9px;
  }

  .btn:not(:last-child) {
    margin-right: 24px;
  }
}

.hero-image_wrapper {
  @include relative;
  @include figure_aspect-ratio("450 / 800");
  flex: 1 1 auto;
  background: map-get($colors, _01-03);
  overflow: hidden;
}
.hero-image {
  @include full-size;
  @include full-width;
  position: absolute !important;
  top: 50%;
  object-fit: cover;
  font-family: "object-fit:cover;";
  height: 100%;
  transform: translateY(-50%);
}

.hero-controls {
  @include flex-column;
  @include absolute;
  justify-content: flex-end;
  top: 48px;
  left: 35%;
  right: 0;
  bottom: 48px;
  pointer-events: none;
  @media screen and (max-width: 768px) {
    @include figure_aspect-ratio("450 / 800");
    top: 0;
    left: 0;
    bottom: auto;
  }
}
.hero-prev,
.hero-next {
  pointer-events: all;
  &:before {
    background: rgba(#000, 0.12);

    @media screen and (max-width: 768px) {
      background: rgba(#000, 0.48);
    }
  }

  svg {
    fill: #fff;
  }

  @media screen and (min-width: 769px) {
    &:not(.swiper-button-disabled):hover:before,
    &:not(.swiper-button-disabled):focus:before {
      background: rgba(#000, 0.48);
    }
  }
}
.hero-pagination {
  pointer-events: all;
  .swiper-pagination-bullet {
    background: rgba(#fff, 0.24);
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}
