/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (ARCHIVE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pgn-archive {
	@include flex-column;
	align-items:center;

	@media screen and (max-width:768px)
	{
		padding:0 24px;
	}
}
	.pgn-archive_list {
		@include flex-row;
		justify-content:center;
		align-items:center;
		width:100%;
		list-style:none;

		@media screen and (max-width:768px)
		{
			justify-content:space-between;
		}
	}
		.pgn-archive_list-item {
		}
			.pgn-archive_list-item_link {
				@include animate;
				@include flex-column;
				justify-content:center;
				align-items:center;
			}

		.pgn-archive_page {
			@include flex-column;
			justify-content:center;

			.pgn-archive_list-item_link {
				margin:0 3px;
				width:48px; height:48px;
				color:map-get($colors, _01-01);
				background:transparent;
				border-radius:100%;
			}

			&.is-current .pgn-archive_list-item_link {
				background:rgba(map-get($colors, _01-01), .06);
			}

			@media screen and (min-width:769px)
			{
				&:not(.is-current):hover .pgn-archive_list-item_link,
				&:not(.is-current):focus .pgn-archive_list-item_link {
					color:map-get($colors, _02-01);
				}
			}
			@media screen and (max-width:768px)
			{
				display:none;
			}
		}

		.pgn-archive_prev,
		.pgn-archive_next {

			.pgn-archive_list-item_link {
				@include animate;
				@include flex-row;
				color:map-get($colors, _01-01);

				svg {
					@include animate;
					display:block;
					width:18px; height:18px;
					fill:map-get($colors, _01-01);
				}
			}

			&.is-disabled .pgn-archive_list-item_link {
				color:map-get($colors, _01-03);

				svg {
					fill:map-get($colors, _01-03);
				}
			}

			@media screen and (min-width:769px)
			{
				&:not(.is-disabled) .pgn-archive_list-item_link:hover,
				&:not(.is-disabled) .pgn-archive_list-item_link:hover {
					color:map-get($colors, _02-01);

					svg {
						fill:map-get($colors, _02-01);
					}
				}
			}
		}
		.pgn-archive_prev {
			padding-right:48px;

			@media screen and (max-width:768px)
			{
				padding-right:24px;
			}

			.pgn-archive_list-item_link svg {
				margin-right:12px;
				transform:rotate(90deg);
			}
		}
		.pgn-archive_next {
			padding-left:48px;

			@media screen and (max-width:768px)
			{
				padding-left:24px;
			}

			.pgn-archive_list-item_link svg {
				margin-left:12px;
				transform:rotate(-90deg);
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (SLIDER) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pgn-slider {
	@include flex-row;
	justify-content:center;
	align-items:center;

	.swiper-pagination-bullet {
		width:12px; height:12px;

		&:not(:last-child) {
			margin-right:8px;
		}

		&:only-child {
			display:none;
		}
	}
}
