/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE MAP ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$map_ratio : ((400 / 800) * 100);

.gmap-wrapper {
	@include relative;
	padding-top:$map_ratio + 0%;
	width:100%;
	background:map-get($colors, _01-03);

	.gmap {
		@include full-size;;
	}
}
