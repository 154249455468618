/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
	display:inline-block;
	font-weight:700;
	box-sizing:border-box;

	&.btn_has-icon {
		@include flex-row;
		align-items:center;

		svg {
			display:block;
			flex-shrink:0;
			width:18px; height:18px;

			@media screen and (max-width:768px)
			{
				width:12px; height:12px;
			}
		}

		.btn-icon {
			flex-shrink:0;
		}

		&:not(.icon-right) .btn-icon {
			padding-right:12px;

			@media screen and (max-width:768px)
			{
				padding-right:9px;
			}
		}
	}

	&.icon-minus-90-deg svg {
		transform:rotate(-90deg);
	}

	&.btn-ghost {
		opacity:.24;

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				opacity:1;
			}
		}
	}

	&.btn-round {
		@include flex-column;
		justify-content:center;
		align-items:center;
		width:48px; height:48px;
		border-radius:100%;
		overflow:hidden;

		svg {
			display:block;
			flex-shrink:0;
			width:18px; height:18px;
		}
	}

	&.icon-right .btn-icon {
		order:2;
		padding-left:12px;

		@media screen and (max-width:768px)
		{
			padding-left:9px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-01 {
	padding:16px 32px;
	border-radius:9999px;

	@media screen and (max-width:768px)
	{
		padding:13px 32px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 2) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-02 {
	@include flex-row;
	align-items:center;
	text-decoration:underline;

	&:after {
		content:"";
		display:block;
		margin-top:2px;
		width:8px; height:8px;
		background:#FFF;
		border-radius:100%;
	}

	.btn-label {
		padding-right:9px;
	}

	&.color_01-01:after,
	&.color_01-02:after,
	&.color_02-03:after {
		background:map-get($colors, _01-01);
	}
	&.color_01-03:after {
		background:map-get($colors, _01-02);
	}
	&.color_02-01:after {
		background:map-get($colors, _02-01);
	}
	&.color_02-02:after {
		background:map-get($colors, _02-02);
	}
	&.color_02-04:after {
		background:map-get($colors, _02-04);
	}
	&.color_02-05:after {
		background:map-get($colors, _02-05);
	}
	&.color_02-06:after {
		background:map-get($colors, _02-06);
	}
	&.color_02-07:after {
		background:map-get($colors, _02-07);
	}
	&.color_02-08:after {
		background:map-get($colors, _02-08);
	}

	@media screen and (min-width:769px)
	{
		&:not(:disabled):hover,
		&:not(:disabled):focus {
			text-decoration:none;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 3) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-03 {
	padding:12px 24px;
	font-size:14px;
	text-transform:uppercase;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn-prev,
.btn-next {
	@include animate;
	@include absolute(2);
	justify-content:center;
	top:50%;
	transform:translateY(-50%);
	width:64px; height:128px;
	overflow:hidden;

	&:before {
		@include animate;
		@include absolute;
		content:"";
		top:0; left:0;
		width:128px; height:128px;
		background:map-get($colors, _01-03);
		border-radius:100%;

		@media screen and (max-width:768px)
		{
			background:rgba(map-get($colors, _01-03), .88);
		}
	}

	svg {
		@include animate;
		@include relative(2);
		width:24px !important; height:24px !important;
		fill:map-get($colors, _01-01);
	}

	&.swiper-button-disabled {
		cursor:default;

		&:before,
		svg {
			opacity:0;
		}
	}

	@media screen and (min-width:769px)
	{
		&:not(.swiper-button-disabled):hover:before,
		&:not(.swiper-button-disabled):focus:before {
			background:darken(map-get($colors, _01-03), 9%);
		}
	}
}
.btn-prev {
	left:0;

	&:before {
		transform:translateX(-50%);
	}

	svg {
		margin-left:-8px;
		transform:rotate(180deg);
	}
}
.btn-next {
	right:0;

	svg {
		margin-right:-8px;
	}
}

.btn-prev_height {

	@media screen and (max-width:768px)
	{
		flex-shrink:0;
		width:calc(100vw - 48px);

		&:before {
			@include figure_aspect-ratio('450 / 800');
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (MODAL CLOSE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.modal_btn-close {
	position:fixed;
	top:48px; right:48px;
	z-index:9;

	svg {
		@include animate;
	}

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			transform:rotate(180deg);
		}
	}
	@media screen and (max-width:768px)
	{
		top:12px; right:12px;
	}
}
