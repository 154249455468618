/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-builder {
  & + .page-builder {
    padding-top: 0;
  }
}
.pb-block {
  &:not(.pb-block_full-width):not(:first-child) {
    @media screen and (min-width: 769px) {
      padding-top: 48px;
    }
    @media screen and (max-width: 768px) {
      padding-top: 24px;
    }
  }

  &:not(.pb-block_full-width) + .pb-block.pb-block_full-width {
    @media screen and (min-width: 769px) {
      margin-top: 48px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 24px;
    }
  }
}

.pb-block_full-width {
  margin: 0 -96px;
  width: 100%;

  &:not(.pb-block_spacing-top):first-child {
    margin-top: -96px;

    @media screen and (max-width: 1220px) and (min-width: 769px) {
      margin-top: -48px;
    }
    @media screen and (max-width: 768px) {
      margin-top: -24px;
    }
  }
  &:last-child {
    margin-bottom: -96px;
  }

  @media screen and (max-width: 1220px) and (min-width: 769px) {
    margin: 0 -48px;

    &:first-child {
      margin-top: -48px;
    }
    &:last-child {
      margin-bottom: -48px;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0 -24px;

    &:first-child {
      margin-top: -24px;
    }
    &:last-child {
      margin-bottom: -24px;
    }
  }
}

.pb-block_full-width-caption {
  margin-top: 16px;
  font-size: 14px;
}

.pb-block_stretch {
  width: calc(100% + 192px);

  @media screen and (max-width: 1220px) and (min-width: 769px) {
    width: calc(100% + 96px);
  }
  @media screen and (max-width: 768px) {
    width: calc(100% + 48px);
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (MEDIA + TEXT) -------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_media-text {
  .grid > .column + .column {
    @media screen and (max-width: 768px) {
      padding-top: 24px;
    }
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (ARCHIVE) ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_archive {
  .events {
    padding-top: 0;
  }

  .article-item {
    padding-top: 48px;

    @media screen and (max-width: 768px) {
      padding-top: 24px;
    }
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (SPEKTRIX) ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_spektrix-iframe {
  width: 100%;
}
.spektrix-iframe {
  width: 100%;
  border: none;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (CAROUSEL) ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_carousel {
  .srosnops-wrapper {
    margin-top: 48px;
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      margin-top: 0;
    }
  }
  .srosnops {
    margin-top: -48px;

    & > .column {
      padding-top: 48px;
    }
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER BLOCK (BLOCKQUOTE) ---------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_blockquote {
}
.blockquote-quote {
  @include heading(1.6, 1.4, 28px, 22px);
  font-weight: 300;
  font-style: italic;
}

.blockquote-author {
  padding-top: 24px;
  color: rgba(#fff, 0.64);

  @media screen and (max-width: 768px) {
    padding-top: 18px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE (HAS SIDEBAR) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page_has-sidebar {
  .page-builder {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE HEADING ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page-heading {
  @include relative(2);

  &.half-bottom {
    @media screen and (min-width: 1221px) {
      margin-bottom: -48px;
    }
    @media screen and (max-width: 1220px) and (min-width: 769px) {
      margin-bottom: -24px;
    }
  }

  &:not(.half-bottom) {
    margin-bottom: 24px;

    @media screen and (min-width: 1221px) {
      margin-bottom: 48px;
    }
  }

  &--link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  &__link {
    margin-top: 10px;
  }
}
.page-heading_bottom {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;

  @media screen and (max-width: 768px) {
    padding-top: 6px;
  }
}

.pb-block_gallery-thumbs {
  margin-top: 12px;

  .lazyload_wrapper {
    background-color: #000;
  }

  img {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
