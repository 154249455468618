/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOOKING (WIDGET) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.booking-widget {

    @media screen and (max-width:768px)
	{
        padding-top:24px;
    }
}

    .booking-widget_content {
        padding:32px;
        color:#FFF;
        background:map-get($colors, _01-01);

        @media screen and (max-width:768px)
    	{
            padding:24px;
        }
    }
        .booking-widget_heading {
            color:map-get($colors, _02-04);
            font-weight:500;
        }
        .booking-widget_info {
            padding-top:12px;
            line-height:$line_height_small;
            font-size:$font_size_small;

            @media screen and (max-width:768px)
            {
                padding-top:9px;
            }

            strong {
                font-size:14px;
                font-weight:400;
            	text-transform:uppercase;
                opacity:.64;
            }
        }
            .booking-widget_shows {
                list-style:none;
            }
            .booking-widget_info-venue {
                @include animate;
                color:#FFF;
                text-decoration:underline;

                @media screen and (min-width:769px)
        		{
        			&:hover,
        			&:focus {
        				color:map-get($colors, _02-04);
        			}
        		}
            }

    .booking-widget_link {
        @include animate;
        display:block;
        padding:16px 32px;
        color:#FFF;
        font-weight:500;
        text-align:center;
        background:map-get($colors, _02-01);

        @media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background:map-get($colors, _02-02);
			}
		}
    }

.booking-widget_panel {

    @media screen and (max-width:768px)
    {
        background:rgba(#FFF, .88);

        .panel-container {
            @include flex-column;
            flex:1 1 auto;
            padding:24px 32px;

            .booking-widget {
                @include flex-column;
                flex:1 1 auto;
                padding-top:0;
            }
            .booking-widget_content {
                flex:1 1 auto;
                padding:0;
            }
            .booking-widget_link {
                margin-top:24px;
            }
        }

        .panel-close svg {
            fill:map-get($colors, _01-01);
        }
    }
}
