/* ------------------------------------------------------------------------------------------------------------------------ */
/* EVENTS (ARCHIVE) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.events {
    padding-top:48px;

    @media screen and (max-width:768px)
    {
        padding-top:24px;
    }

    &.grid {
        margin-top:-48px;

        @media screen and (max-width:768px)
        {
            margin-top:-24px;
        }
    }
    &.grid-masonry {
        margin-bottom:-48px;
        .event-item {
            height: 100%;
            box-sizing: border-box;
            .grid-masonry_gutter {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
        @media screen and (max-width:768px)
        {
            margin-bottom:-24px;

            .grid-masonry_column {
                width:100%;
            }
        }
    }
}

    .events-heading {
        @include flex-row;
        align-items:flex-end;

        span {
			margin-left:2px;
			line-height:1;

			@media screen and (min-width:769px)
			{
				margin-bottom:-6px;
			}
		}
    }

    .event-item {
        @include flex-column;
        flex:1 1 auto;
        padding-top:48px;

        @media screen and (max-width:768px)
        {
            padding-top:24px;
        }
    }

    .column:not(:first-child) .event-item,
    .grid-masonry_column:not(:first-child) .event-item {

        @media screen and (max-width:768px)
        {
            margin-top:18px; padding-top:24px;
            border-top:1px solid map-get($colors, _01-03);
        }
    }

        .event-thumb {
            @include relative;
            @include figure_aspect-ratio('450 / 800');
            overflow:hidden;

            @media screen and (min-width:769px)
            {
                &:hover .event-thumb_image-wrapper,
                &:focus .event-thumb_image-wrapper {
                    transform:scale(1.05);
                }
            }
        }
            .event-thumb_link {
                @include full-size(3);

            }
                .event-thumb_image-wrapper {
                    @include animate;
                    @include full-size;
                }
                    .event-thumb_image {
                        @include full-size;
                        @include full-width;
                        position:absolute !important;
                        height:100% !important;
                        object-fit:contain; font-family:'object-fit:contain;';
                    }

        .event-content {
            @include flex-column;
            flex:1 1 auto;
            padding:24px 24px 0 0;

            @media screen and (max-width:768px)
            {
                padding:12px 0 0;
            }
        }
            .event-meta,
            .event-price {
                line-height:$line_height_small;
    			color:map-get($colors, _02-01);
    			font-size:$font_size_small;
            }
            .event-price {
                margin-top:auto; padding-top:12px;

                @media screen and (max-width:768px)
                {
                    padding-top:6px;
                }
            }
            .event-heading {
                padding-top:6px;
                line-height:1.4;

                @media screen and (max-width:768px)
                {
                    padding-top:3px;
                }
            }
                .event-heading_link {
                    @include animate;
                    color:map-get($colors, _01-01);

                    @media screen and (min-width:769px)
                    {
                        &:hover,
                        &:focus {
                            color:map-get($colors, _02-02);
                        }
                    }
                }

    .events-filters {
        @include flex-row;
        padding-top:32px;

        @media screen and (max-width:768px)
        {
            padding-top:12px;
        }
    }
        .events-filter {
            @include relative;
            text-transform:none;

            @media screen and (min-width:769px)
            {
                font-size:$font_size_small;
            }
            @media screen and (max-width:768px)
            {
                @include flex-row;
                align-items:center;
                padding:0;
                color:map-get($colors, _01-01) !important;
                background:none !important;
            }

            &:not(:last-child) {
                margin-right:6px;

                @media screen and (max-width:768px)
                {
                    margin-right:12px;
                }
            }

            .btn-label {
                @include relative;

                @media screen and (max-width:768px)
                {
                    order:2;
                    padding-left:9px;
                }
            }
            .btn-bg {
                width:4px;

                @media screen and (min-width:769px)
                {
                    @include animate;
                    @include absolute(-1);
                    top:0; left:0; bottom:0;
                }
                @media screen and (max-width:768px)
                {
                    width:8px; height:8px;
                    border-radius:100%;
                }
            }

            &:first-child .btn-bg {

                @media screen and (min-width:769px)
                {
                    width:0;
                }
            }

            @media screen and (min-width:769px)
        	{
        		&:not(:disabled):not(.active):hover .btn-bg,
        		&:not(:disabled):not(.active):focus .btn-bg {
                    width:100%;
        		}
        	}

            &.active {
                cursor:default;

                @media screen and (max-width:768px)
                {
                    text-decoration:underline;
                }

                .btn-bg {

                    @media screen and (min-width:769px)
                    {
                        width:100%;
                    }
                }
            }
        }

.events_btn-all_wrapper {
	@include flex-column;
	align-items:center;
    padding-top:48px;

    @media screen and (max-width:768px)
    {
        padding-top:24px;
    }
}

.filter-date {
    @include flex-row;
}
    .filter-date_submit {
        @include animate;
        @include flex-column;
        justify-content:center;
    	align-items:center;
        flex-shrink:0;
        margin-left:3px;
        width:50px; height:50px;
        background:map-get($colors, _02-04) !important;
        border-radius:3px;

        svg {
            display:block;
            width:14px; height:14px;
            fill:#FFF;
            transform:rotate(-90deg);
        }

        @media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				background:map-get($colors, _01-01) !important;
			}
		}
    }
