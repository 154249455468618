/* ------------------------------------------------------------------------------------------------------------------------ */
/* SPONSORS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.srosnops-wrapper {
	margin-top:96px; padding:48px 96px;
	border-top:1px solid map-get($colors, _01-03); border-bottom:1px solid map-get($colors, _01-03);

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		margin-top:48px; padding:48px;
	}
	@media screen and (max-width:768px)
	{
		margin-top:24px; padding:24px;
	}
}
	.srosnop-item {
		@include relative;
		max-height:64px;
		overflow:hidden;

		&:before {
			@include relative;
            @include figure_aspect-ratio('9 / 16');
        }
	}
		.srosnop-link {
			@include full-size(3);
		}
		.srosnop-logo_wrapper {
			@include full-size;
		}
			.srosnop-logo {
                height:100% !important;
                object-fit:contain; font-family:'object-fit:contain;';
			}
