/* BASE */
@import "base/vars.scss";
@import "base/reset.scss";
@import "base/classes.scss";
@import "base/grids.scss";
@import "base/forms.scss";
@import "base/typography.scss";

/* FUNCTIONS */
@import "functions/cookie-notice.scss";
@import "functions/flash-message.scss";
@import "functions/flatpickr.scss";
@import "functions/modal-window.scss";
@import "functions/panel.scss";
@import "functions/preload.scss";

/* PLUGINS */
@import "plugins/lazysizes.scss";
@import "plugins/swiper.scss";
@import "plugins/plyr.scss";

/* COMPONENTS */
@import "components/boxes.scss";
@import "components/buttons.scss";
@import "components/fastlinks.scss";
@import "components/forms.scss";

@import "components/archives.scss";
@import "components/articles.scss";
@import "components/booking.scss";
@import "components/events.scss";
@import "components/google-maps.scss";
@import "components/hero.scss";
@import "components/logo.scss";
@import "components/navigations.scss";
@import "components/video.scss";
@import "components/typography.scss";
@import "components/page-builder.scss";
@import "components/paginations.scss";
@import "components/search.scss";
@import "components/share.scss";
@import "components/sponsors.scss";
@import "components/offers.scss";

/* LAYOUT */
@import "layout/wrapper.scss";
@import "layout/header.scss";
@import "layout/footer.scss";
