/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper {
	@include relative;

	picture,
	img {
		@include animate;
		@include relative;
		@include full-width;
		opacity:0;

		&.lazyloaded {
			opacity:1;
		}
	}
	picture.lazyloaded img {
		opacity:1;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js {

	.lazyload_wrapper picture,
	.lazyload_wrapper img {
		opacity:1;
	}
}
