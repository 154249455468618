/* --------------------------------------------------------------------------------------------------- */
/* SHARE --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.nav-share_wrapper {
	padding:0 96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:0 48px;
	}
	@media screen and (max-width:768px)
	{
		padding:0 24px;
	}

	& > .box-content {
		@include flex-column;
		align-items:flex-start;
	}
}
	.nav-share {
		@include flex-row;
		align-items:center;
		padding:24px 48px 0 0;
		border-top:1px solid map-get($colors, _01-03);
	}
		.nav-share_label {
			display:block;
			padding-right:24px;
			line-height:1;
		}
