/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo,
.logo svg {
	@include animate;
	display:block;
	width:auto; height:96px;

	@media screen and (max-width:768px)
	{
		height:48px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IS STICKY HEADER ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.is-sticky-header {

	.logo,
	.logo svg {

		@media screen and (min-width:769px)
		{
			height:48px;
		}
	}
}
