/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$colors: (
    _white: #FFF,
    _01-01: #4E626F,
    _01-02: #4E626F,
    _01-03: #ECEDED,
    _02-01: #FF6600,
    _02-02: #ED326E,
    _02-03: #4E626F,
    _02-04: #77BDBD,
    _02-05: #6C9C9B,
    _02-06: #6699CC,
    _02-07: #CCCC33,
    _02-08: #9B3334,
    _error: #D91E18,
    _alert: #F89406,
    _valid: #00B16A,
    _update: #1E8BC3
);

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* Roboto 400 (Regular) */
@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:400;
  src:url('../fonts/roboto/raleway-v14-latin-regular.eot');
  src:local('Roboto'), local('Roboto-Regular'),
      url('../fonts/roboto/raleway-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/roboto/raleway-v14-latin-regular.woff2') format('woff2'),
      url('../fonts/roboto/raleway-v14-latin-regular.woff') format('woff'),
      url('../fonts/roboto/raleway-v14-latin-regular.ttf') format('truetype'),
      url('../fonts/roboto/raleway-v14-latin-regular.svg#Roboto') format('svg');
}

/* Roboto 500 (Medium) */
@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:500;
  src:url('../fonts/roboto/raleway-v14-latin-500.eot');
  src:local('Roboto Medium'), local('Roboto-Medium'),
	  url('../fonts/roboto/raleway-v14-latin-500.eot?#iefix') format('embedded-opentype'),
	  url('../fonts/roboto/raleway-v14-latin-500.woff2') format('woff2'),
	  url('../fonts/roboto/raleway-v14-latin-500.woff') format('woff'),
	  url('../fonts/roboto/raleway-v14-latin-500.ttf') format('truetype'),
	  url('../fonts/roboto/raleway-v14-latin-500.svg#Roboto') format('svg');
}

/* Roboto 700 (Bold) */
@font-face {
  font-family:'Roboto';
  font-style:normal;
  font-weight:700;
  src:url('../fonts/roboto/raleway-v14-latin-700.eot');
  src:local('Roboto Bold'), local('Roboto-Bold'),
	  url('../fonts/roboto/raleway-v14-latin-700.eot?#iefix') format('embedded-opentype'),
	  url('../fonts/roboto/raleway-v14-latin-700.woff2') format('woff2'),
	  url('../fonts/roboto/raleway-v14-latin-700.woff') format('woff'),
	  url('../fonts/roboto/raleway-v14-latin-700.ttf') format('truetype'),
	  url('../fonts/roboto/raleway-v14-latin-700.svg#Roboto') format('svg');
}

$font_family_01:'Raleway', sans-serif;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_size_base:18px;
$font_size_small:16px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$line_height_base:1.6;
$line_height_small:1.5;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$site_width_large:1440px;
$site_width_base:1220px;
$site_width_medium:980px;
$site_width_small :640px;
$site_width_xsmall:480px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEIGHTS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$header_height_01-01 : 161px;
$header_height_01-02 : 113px;
$header_height_01-03 : 73px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1:unit($min-vw);
	$u2:unit($max-vw);
	$u3:unit($min-font-size);
	$u4:unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size:$min-font-size;
			@media screen and (min-width:$min-vw) {
				font-size:calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width:$max-vw) {
				font-size:$max-font-size;
			}
		}
	}
}
