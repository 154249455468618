/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field {
	padding-top:24px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
	.field-label {
		@include flex-row;
		padding-bottom:6px;
		color:map-get($colors, _01-01);
		font-weight:500;

		em {
			margin-left:auto; padding-left:24px;
			color:map-get($colors, _01-01);
			font-weight:400;
			text-transform:none;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-feedback {
	@include animate;
	@include relative;
	max-height:0;
	overflow:hidden;
	opacity:0;

	span {
		display:block;
		padding-top:12px;
		line-height:$line_height_small;
		font-size:$font_size_small;
		color:map-get($colors, _error);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.badge-valid,
.badge-invalid {
	@include animate;
	@include flex-column;
	justify-content:center;
	align-items:center;
	position:absolute;
	top:-12px; right:-12px;
	z-index:2;
	width:24px; height:24px;
	border-radius:100%;
	opacity:0;

	svg {
		display:block;
		width:12px; height:12px;
		fill:#FFF;
	}
}
.badge-valid {
	background:map-get($colors, _valid);
}
.badge-invalid {
	background:map-get($colors, _error);
}

.input-radio,
.input-checkbox {

	.badge-valid,
	.badge-invalid {
		top:0; left:26px; right:auto;
		margin-top:-2px;
		width:8px; height:8px;

		svg {
			display:none;
		}
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.badge-invalid {
		opacity:0;
	}
	.badge-valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.badge-valid {
		opacity:0;
	}
	.field-feedback,
	.badge-invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-text,
.input-datepicker {
	@include animate;
	@include relative;
    background:#FFF;
	border:2px solid rgba(map-get($colors, _01-01), .12);
	border-radius:3px;

	input,
	textarea {
		@include animate;
		margin:0; padding:16px 24px;
		width:100%; height:auto;
		line-height:$line_height_base;
		color:map-get($colors, _01-01);
		font-family:$font_family_01;
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:13px 24px;
		}
	}
	input {

		&:not([value=""]) + .label_in-field,
		&:focus + .label_in-field {
			z-index:1;
			padding:6px 24px;
			font-size:8px;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				position:relative;
				z-index:2;
			}
		}

		&:disabled {
			color:rgba(map-get($colors, _01-01), .24);
			cursor:default;

			& + svg {
				opacity:.24;
			}
		}
	}

	.label_in-field {
		@include animate;
		@include full-size;
		padding:16px 24px;
		line-height:$line_height_small;
		color:map-get($colors, _01-01);
		font-size:$font_size_base;
		box-sizing:border-box;
		cursor:pointer;
	}
}

.input-datepicker_wrapper {

	@media screen and (max-width:768px)
	{
		width:100%;
	}
}
	.input-datepicker {
		@include flex-row;

		.datepicker-clear {
			@include flex-column;
			justify-content:center;
			align-items:center;
			flex-shrink:0;
			width:46px; height:46px;
			border-left:2px solid rgba(map-get($colors, _01-01), .12);

			svg {
				@include animate;
				display:block;
				width:12px; height:12px;
				fill:map-get($colors, _01-01);
			}
		}

		input {
			padding:9px 18px;
			min-width:234px; max-width:244px;
			font-size:$font_size_small;

			@media screen and (max-width:768px)
			{
				min-width:208px;
			}
		}
		input:disabled + .datepicker-clear {
			cursor:default;

			svg {
				opacity:.24;
			}
		}

		@media screen and (min-width:769px)
		{
			input:not(:disabled) + .datepicker-clear:hover svg,
			input:not(:disabled) + .datepicker-clear:focus svg {
				fill:map-get($colors, _02-01);
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.field-select {
	@include relative;

	select {
		@include animate;
		@include relative;
		margin:0; padding:16px 58px 16px 24px;
		width:100%; height:auto;
		line-height:$line_height_base;
		color:map-get($colors, _01-01);
		font-family:$font_family_01;
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		background:#FFF;
		border:1px solid rgba(map-get($colors, _01-01), .12);
		border-radius:3px;
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:13px 58px 13px 24px;
		}

		&:disabled {
			color:map-get($colors, _01-01);
			cursor:default;

			& + .chevron {
				opacity:.24;
			}
		}
	}

	.chevron {
		@include animate;
		position:absolute;
		top:50%; right:24px;
		z-index:2;
		margin-top:-6px;
		width:12px; height:12px;
		transform:rotate(90deg);

		svg {
			display:block;
			width:12px; height:12px;
			fill:map-get($colors, _01-01);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.input-radio,
.input-checkbox {
	@include animate;
	@include relative;
	@include flex-row;
	align-items:center;

	input {
		position:absolute;
		top:50%; left:0;
		z-index:1;
		margin:0; padding:0;
		width:32px; height:32px;
		line-height:1;
		color:map-get($colors, _01-01);
		font-size:100%; font-size:$font_size_base;
		box-sizing:border-box;
		background:none;
		border:0 solid;
		border-radius:0;
		opacity:0;
		cursor:pointer;
		appearance:none;
		transform:translateY(-50%);

		&:checked:not(:disabled) + span,
		&:checked:not(:disabled) + span {

			&:after,
			svg {
				opacity:1;
			}
		}

		&:disabled {
			cursor:default;

			& + span + label {
				opacity:.24;
				cursor:default;
			}
		}
	}

	input + span {
		@include animate;
		@include flex-column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:32px; height:32px;
		border:2px solid rgba(map-get($colors, _01-01), .12);
		border-radius:3px;
		box-sizing:border-box;

		&:after,
		svg {
			@include animate;
			display:block;
			opacity:0;
		}
	}

	input + span + label {
		flex:1 1 auto;
		flex-direction:column;
		margin:0; padding:0 0 0 12px;
		line-height:1.2;
		text-align:left;
		cursor:pointer;
	}

	& + .field-feedback:before {
		left:6px;
	}
}

.input-radio {

	input + span {
		border-radius:100%;

		&:after {
			content:"";
			width:8px; height:8px;
			background:map-get($colors, _01-01);
			border-radius:100%;
		}
	}
}

.input-checkbox {

	input + span {

		svg {
			width:16px; height:16px;
			fill:map-get($colors, _01-01);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.g-recaptcha {
	display:none;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form-wrapper {
}
	.form {
		margin-top:-24px;

		@media screen and (max-width:768px)
		{
			margin-top:-18px;
		}

		a {
			@include animate;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:map-get($colors, _02-01);
					text-decoration:none;
				}
			}
		}
	}
		.form-btn_wrapper {
			@include flex-row;
			align-items:flex-end;
			padding-top:24px;

			.btn {
				min-width:160px;
			}
		}
		.form-bottom {
			padding-top:48px;
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (SEARCH) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form-search_wrapper {

	@media screen and (max-width:768px)
	{
		background:rgba(map-get($colors, _01-01), .88);
	}
}
	.form-search_box {

		@media screen and (max-width:768px)
		{
			padding:20px 24px;
		}
	}

	.form-search {
		margin-top:0 !important;

		.field {
			padding:0 !important;

			@media screen and (max-width:768px)
			{
				margin-right:60px;
			}
		}

		.input-text {
			background:none;
			border:none;

			input,
			.label_in-field {
				padding:0;
				color:#FFF;
				@include fluid-type(312px, 884px, 24px, 64px);
			}
			input {
				line-height:1;

				&:not([value=""]) + .label_in-field,
				&:focus + .label_in-field {
					padding:0;
				}
			}
			.label_in-field {
				line-height:1.2;
			}
		}

		button {
			display:none;
		}
	}
