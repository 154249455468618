/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
}

    .footer-lhc > .grid > .column:not(:first-child),
    .footer-rhc,
    .footer-rhc_bottom {

        @media screen and (max-width:768px)
        {
            padding-top:18px;
        }
    }

    .footer-rhc {
        @include flex-column;
        justify-content:space-between;
        flex:1 1 auto;
    }

    .footer-bottom {
        padding-top:48px;
        line-height:$line_height_small;
        font-size:$font_size_small;

        @media screen and (max-width:768px)
        {
            padding-top:24px;
            line-height:1.4;
            font-size:14px;
        }
    }

    .btn.footer_newsletter-trigger {
        font-size:24px;
        text-decoration:none;

        &:after {
            background:map-get($colors, _02-01);
        }

        @media screen and (min-width:769px)
        {
            &:not(:disabled):hover,
            &:not(:disabled):focus {
                color:map-get($colors, _02-01) !important;
            }
        }
    }
