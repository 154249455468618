/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS (BAND) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.fastlink-band_wrapper {
	padding:48px 96px;

	@media screen and (max-width:1220px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}
}
	.fastlink-band {

		@media screen and (max-width:768px)
		{
			margin:24px;
		}
	}
		.fastlink-band_heading {
			text-transform:uppercase;
		}
		.fastlink-band_cta-wrapper {
			@include flex-column;
			align-items:center;
		}

.fastlink-band_prev,
.fastlink-band_next {

	&:before {
		background:rgba(#FFF, .12);
	}

	svg {
		fill:#FFF;
	}

	@media screen and (min-width:769px)
	{
		&:not(.swiper-button-disabled):hover:before,
		&:not(.swiper-button-disabled):focus:before {
			background:rgba(#FFF, .24);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS (GRID) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.fastlink-grid_item {
	@include relative;
	overflow:hidden;

	&:before {
		@include full-size;
		content:"";
		z-index:2;
		background:linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, .88) 100%);
	}

	@media screen and (min-width:769px)
	{
		&:hover .fastlink-grid_item_image-wrapper,
		&:focus .fastlink-grid_item_image-wrapper {
			transform:scale(1.05);
		}
	}
}
	.fastlink-grid_link {
		@include full-size;
		z-index:4;
	}

	.fastlink-grid_heading {
		// @include flex-row;
		@include absolute(3);
		// align-items:flex-end;
		left:32px; right:32px; bottom:32px;
		color:#FFF;

		span {
			display:inline-block;
			margin-left:2px;
			line-height:1;
			transform:translateY(8px);

			/*
			@media screen and (min-width:769px)
			{
				margin-bottom:-2px;
			}
			*/

			@media screen and (max-width:768px)
			{
				transform:translateY(6px);
			}
		}
	}

	.fastlink-grid_item_image-wrapper {
		@include animate;
		@include relative;
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS (BOXES) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.fastlink-boxes {
	margin-top:-48px;

	@media screen and (max-width:768px)
	{
		margin-top:-24px;
	}
}

	.fastlink-boxes_item {
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}

		.fastlink-boxes_image {
			@include relative;
			overflow:hidden;

			@media screen and (min-width:769px)
			{
				&:hover .fastlink-boxes_image-wrapper,
				&:focus .fastlink-boxes_image-wrapper {
					transform:scale(1.05);
				}
			}
		}
			.fastlink-boxes_image-link {
				@include full-size;
				z-index:2;
			}
			.fastlink-boxes_image-wrapper {
				@include animate;
				@include relative;
			}

		.fastlink-boxes_heading {
			padding:24px 24px 0 0;

			@media screen and (max-width:768px)
			{
				padding:12px 0 0;
			}
		}
			.fastlink-boxes_heading-link {
				@include animate;
				@include flex-row;
				align-items:center;

				svg {
					display:block;
					width:24px; height:24px;
					fill:map-get($colors, _02-01);
				}

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:map-get($colors, _02-01);
					}
				}
			}
				.fastlink-boxes_heading-link_label {
					padding-right:12px;
				}
