/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
	color:map-get($colors, _01-01);

	h1, h2, h3, h4, h5, h6, strong {
		color:map-get($colors, _01-01);
	}

	ul, ol {
		padding-left:calc(1.5em + 19px);

		@media screen and (max-width:768px)
		{
			padding-left:calc(1em + 16px);
		}

		li {
			line-height:$line_height_small;

			@media screen and (max-width:768px)
			{
				line-height:1.4;
			}

			&:not(:first-child) {
				padding-top:.5em;
			}
		}
	}

	a:not(.btn):not(.link) {
		@include animate;
		color:map-get($colors, _02-01);
		text-decoration:underline;
		word-break:break-all;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:map-get($colors, _02-02);
				text-decoration:none;
			}
		}
	}

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}
	}

	&.half-spacing {

		h1, h2, h3, h4, h5, h6, p, ul, ol {
			padding-top:.75em;

			@media screen and (max-width:768px)
			{
				padding-top:.375em;
			}

			&:first-child {
				padding-top:0;
			}
		}
	}

	&.typo-light {
		color:#FFF;

		h1, h2, h3, h4, h5, h6, strong {
			color:#FFF;
		}

		a:not(.btn):not(.link) {
			color:#FFF;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:#FFF;
				}
			}
		}
	}
}
